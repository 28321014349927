import { Component, Input } from '@angular/core';
import { MoreDropdownItem } from '../../../models/more-dropdown.config';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-more-dropdown',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './more-dropdown.component.html',
  styleUrl: './more-dropdown.component.scss',
})
export class MoreDropdownComponent {
  @Input() enableItems: string[] = [];
  filteredItems: MoreDropdownItem[] = [];

  items: MoreDropdownItem[] = [
    {
      label: 'Template',
      icon: 'template-action',
      action: 'template',
    },
    {
      label: 'Import',
      icon: 'import-action',
      action: 'import',
    },
    {
      label: 'Export',
      icon: 'export-action',
      action: 'export',
    },
    {
      label: 'Archive',
      icon: 'archive-action',
      action: 'archive',
    },
    {
      label: 'Delete',
      icon: 'delete-action',
      action: 'delete',
    },
    {
      label: 'Refresh',
      icon: 'refresh-action',
      action: 'refresh',
    },
  ];

  get filterItems() {
    if (this.enableItems.length > 0) {
      this.filteredItems = this.items.filter((item) =>
        this.enableItems.includes(item.action)
      );
    } else {
      this.filteredItems = this.items;
    }
    return this.filteredItems;
  }

  performAction(action: string) {
  }
}
