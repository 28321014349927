<div class="dropdown more-dropdown btn-grey me-2">
  <button class="btn btn-secondary dropdown-toggle btn-grey" type="button" data-bs-toggle="dropdown">
    More
  </button>
  <ul class="dropdown-menu" aria-labelledby="more-dropdown-button">
    @for (item of filterItems; track $index) {
      <li>
        <a class="dropdown-item" (click)="performAction(item.action)">
          <app-icon [icon]="item.icon" [class]="'me-1'"></app-icon>{{ item.label }}
        </a>
      </li>
    }
  </ul>
</div>
